.simplemenulist {
  display: inline-block;
}

.simplemenulist .hl {
  background-color: rgb(210, 106, 106);
}

.simpleMenuListContent {
  position: absolute;
  margin-top: 13px;
  margin-left: -100px;
  padding-bottom: 1rem;
  padding-top: 1rem;
}
.simpleMenuListContent a {
  /* text-align: center !important; */
  display: block;
}
.simplemenulist .collapsed {
  display: none;
}

/* .simplemenulist .collapsedButton {
  display: block !important;
  text-align: left !important;
} */

/* .collapsedButton a {
  text-align: left !important;
  padding: 0.5rem 0;
} */

.simplemenulist .collapsedButton:hover .collapsed {
  display: block;
}
