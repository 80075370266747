.gridList {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.gridListItem {
  width: 33.33%;
  cursor: pointer;
}

.gridListItem img {
  width: 100%;
  display: block;
  line-height: 1rem;
}

body > .fullscreen {
  position: relative;
  z-index: 99999;
}

.lightBox .buttonCloseFull {
  outline: none !important;
  background: url("/assets/close.png") no-repeat;
  background-color: rgba(0, 0, 0, 0.35);
  background-size: cover;
  border: 0;
  top: 1.4rem;
  cursor: pointer;
  width: 2.6rem;
  height: 2.6rem;
  position: absolute;
  right: 1.4rem;
  transition: background-color 0.05s ease;
  -webkit-transition: background-color 0.05s ease;
  box-sizing: border-box;
  border-radius: 0.4rem;
  z-index: 9999;
}

@media (max-width: 640px) {
  .gridListItem {
    width: 33.33%;
  }
}
