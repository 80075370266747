.contentInner {
  margin: 0 auto;
  padding: 2rem 2rem;
  box-sizing: border-box;
}
.contentPara {
  display: block;
  margin: 0 auto;
  width: 100%;
  /* font-size: 1.2rem; */
  /* line-height: 2rem; */
}

.contentContainer h2 {
  font-size: 4rem;
  text-shadow: 0.5rem 0rem 0rem #0a0a0a;
}
.contentContainer h1 {
  font-size: 6.8rem;
  text-shadow: 0.7rem 0.7rem #0a0a0a;
}

.contentInner img {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  /* .activityTitle {
    font-size: 4.6rem;
    margin-top: -2.2rem;
  } */

  .contentContainer h1 {
    font-size: 4.2rem;
    text-shadow: 0.5rem 0.5rem 0rem #0a0a0a;
  }
  .contentContainer h2 {
    font-size: 2.6rem;
    text-shadow: 0.5rem 0.5rem 0rem #0a0a0a;
  }
}

@media (max-width: 1024px) {
  .contentContainer h1 {
    font-size: 2.6rem;
  }

  .contentContainer h2 {
    font-size: 1.6rem;
  }

  .contentContainer h3 {
    font-size: 1.2rem;
  }
}
