.customFormContainer {
  padding: 1rem;
}

.customFormInner {
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
}

.customFormFields {
  display: flex;
  flex-wrap: wrap;
}

.customFormInner .content {
  padding: 1rem;
  box-sizing: border-box;
}

.customFormFields > div {
  box-sizing: border-box;
  padding: 0 0.7rem;
}

.customFormFields .textarea textarea {
  height: 200px !important;
}

.customFormSend {
  width: 100%;
  max-width: 200px;
  margin: 1rem 0 !important;
  display: block !important;
  height: 3rem !important;
  float: right;
}

/* .customFormField {
  box-sizing: border-box;
  padding: 0.2rem;
} */
