.socials {
  padding: 2rem 1rem 2rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.social-icon {
  display: inline-block;

  margin: 1rem;
  width: 4rem !important;
  height: 4rem !important;
}
