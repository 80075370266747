.footerLogo {
  display: block;
  /* margin: 0 auto; */
}

.footerlogolink {
  display: inline-block;
  vertical-align: middle;
}

.footerInner p {
  font-size: 0.82rem;
  display: inline-block;
  padding: 2rem;
  vertical-align: middle;
}

.footerInner {
  padding: 2rem 2rem 6rem 2rem;
  max-width: 1280px;
  margin: 0 auto;
}

.footerInner p {
  display: inline-block;
  vertical-align: middle;
}

.footerTitle {
  opacity: 0.6;
}

.footerLink:hover {
  opacity: 1;
}

.footerLink {
  opacity: 0.6;
}

.socialfooter {
  float: right;
}

.footerNav {
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: solid 1px #333;
  margin-top: 2rem;
}

.footerList li span {
  font-weight: bold !important;
}

.footerNav li,
.footerNav a {
  padding-bottom: 0;
  padding-top: 0;
}

@media (max-width: 1024px) {
  .footerList {
    width: 50%;
  }

  .footerList .MuiListItem-root {
    text-align: center;
  }
}

@media (max-width: 640px) {
  .footerList {
    width: 100%;
  }

  .footerLogo {
    margin: 0 auto;
    display: inline-block;
  }

  .footerlogolink {
    text-align: center;
    display: block;
    vertical-align: middle;
  }

  .socialfooter {
    text-align: center;
    float: none;
  }

  .footerInner p {
    text-align: center;
    display: block;
    padding: 2rem 0;
  }
}
