.articleInner {
  background-color: #f1f1f1;
  padding-bottom: 6rem;
}

.articleInner .articleImage {
  width: 100%;
  height: auto;
}

.articleInner .articleContent {
  max-width: 1024px;
  padding: 0 2rem 6rem 2rem;
  margin: -12rem auto 0 auto;
  position: relative;
  /* z-index: 5; */
  background-color: #fff;
}
.articleInner .date {
  text-align: center;
  color: #ccc;
  padding-bottom: 2rem;
}

.articleInner .articleContent img {
  max-width: 100%;
}
.articleInner .articleContent p {
  padding: 0 10% 1rem 10%;
}

@media (max-width: 1024px) {
  .articleInner .articleContent {
    margin: -2rem 2rem 2rem;
  }
}

@media (max-width: 640px) {
  .articleInner .articleContent p {
    padding: 0 0 1rem 0;
  }
}
