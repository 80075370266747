.actions {
  padding: 2rem 1rem 2rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.actions a {
  color: #0a0a0a;
  margin: 1rem;
  min-width: 160px;
  padding: 1rem;
}
