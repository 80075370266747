.iconLinks {
  display: block;
  text-align: center;
}

.iconLinks .iconLink {
  display: inline-block;
  text-decoration: none;
  padding: 2rem;
  min-width: 160px;
  text-align: center;
}

.iconLinks .iconLink img {
  width: 100px;
  fill: pink;
}

.iconLinks .iconLink span {
  padding-top: 1rem;
  display: block;
  font-weight: bold;
}

.iconLinks .iconLink img,
.iconLinks .iconLink span {
  vertical-align: middle;
}
