.counters {
  padding: 6rem 2rem 2rem 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  max-width: 1280px;
}
.counters .counter {
  position: relative;
  text-align: center;
  padding: 0.7rem;
  width: 120px;
  height: 160px;
}

.counters .donut {
  position: absolute;
  top: -31px;
  width: 130px;
  left: 50%;
  margin-left: -65px;
}
.counters .number {
  font-size: 2rem;
  font-weight: 900;
  font-style: italic;
}
.counters .content {
  font-size: 0.8rem;
  background-color: #007993;
  position: relative;
  padding: 0.5rem;
  border-radius: 0 2rem 0;
  color: #fff;
  margin-top: 0.5rem;
}

@media (min-width: 1025px) {
  .counters {
    padding: 6rem 2rem 2rem 2rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    max-width: 1280px;
  }

  .counters .counter {
    position: relative;
    text-align: center;
    padding: 1rem;

    width: 300px;
    height: 200px;
    padding-top: 4rem;
  }

  .counters .donut {
    position: absolute;
    top: -33px;
    width: 200px;
    left: 50%;
    margin-left: -100px;
  }

  .counters .number {
    font-size: 2.6rem;
    font-weight: 900;
    font-style: italic;
    position: relative;
    top: -1.6rem;
  }

  .counters .content {
    font-size: 1rem;
    background-color: #007993;
    position: relative;
    padding: 0.5rem;
    border-radius: 0 2rem 0;
    color: #fff;
    width: 150px;
    margin: 0 auto;
    font-weight: 900;
  }
}
