.bottomNav {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
}

.bottomNavSpacer {
  display: none;
}

@media (max-width: 640px) {
  .bottomNav {
    padding-bottom: 1rem;
  }
}
