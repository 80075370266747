.idGalleryContainer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.8);

  align-items: center;
  justify-content: center;
}

.idGalleryContainer.open {
  display: flex;
}

#closeIdGallery {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  color: #ffffff;
  border: none;
  margin: 1rem;
  cursor: pointer;
}
