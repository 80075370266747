.titleContainer {
}
.titleContainer .title {
  font-size: 2.6rem;
  position: relative;
  line-height: 1em;
  z-index: 2;
  margin: 0;

  padding: 2rem;
}

.titleContainer .title.offset {
  margin-top: -7.3rem;
}

@media (max-width: 1024px) {
  .titleContainer .title {
    font-size: 2.2rem;
    margin: 0 !important;
  }

  .titleContainer .title.offset {
    margin-top: -5rem !important;
  }
}

@media (max-height: 640px) {
  .titleContainer .title {
    font-size: 2.2rem;
    margin: 0 !important;
  }
  .titleContainer .title.offset {
    margin-top: -5rem !important;
  }
}

@media (max-width: 640px) {
  .titleContainer .title {
    font-size: 2.2rem;
    padding-bottom: 1rem;
  }
  .titleContainer .title.offset {
    margin-top: -4rem !important;
  }
}
