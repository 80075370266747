.articleFeedCard {
}

.articlesFeed {
}

.articlesFeed .cta {
  margin: 0rem auto 2rem auto;

  width: auto;
}
.articlesFeedContainerInner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
}
.articlesFeedContainerInner > .articleFeedCard {
  text-decoration: none;
  width: 100%;
  max-width: 300px;
  margin: 1rem 1rem;
  box-shadow: 0rem 0.2rem 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
}

.articlesFeedContainerInner > .articleFeedCard > div {
  height: 50vh;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-content: flex-end;
  justify-content: left;
  flex-wrap: wrap;
}

.articlesFeedContainerInner > .articleFeedCard > div div {
  width: 100%;
  background-color: #333333d6;
  color: #fff;
  padding: 0 1rem;
}
.articlesFeedContainerInner .articleTitle {
  font-size: 1.2rem;
  font-weight: bold;
}

.articleDate {
  padding-bottom: 0.8rem !important;
}
@media (max-width: 640px) {
  .articlesFeedContainerInner > .articleFeedCard {
    max-width: 100%;
  }
  .articlesFeedContainerInner > .articleFeedCard:first-child .articleTitle {
    font-size: 1.6rem;
  }
}
