.privacyPopUp {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem;

  width: 100%;
  box-sizing: border-box;
  z-index: 1001;
}

.privacyPopUpInner .buttons {
  display: block;
  text-align: center;
}

.privacyPopUpInner .text {
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width: 1024px;
}

.privacyPopUpInner .buttons > * {
  display: inline-block;
  margin-right: 1rem;
  margin-top: 1rem;
}
