.infos {
  /* padding: 2rem; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1024px;
  margin: 0 auto;
}

.infos .info {
  width: 100%;
  max-width: 210px;
  padding: 1rem;
}

.infos .info a {
  text-decoration: none;
}

.infos .info a:hover {
  text-decoration: underline;
}
.infos .info h2 {
  line-height: 1em;
}
.info img {
  width: 100%;
  height: auto;
  display: block;
}
