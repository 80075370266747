.react-parallax img {
  display: block;
  line-height: 0;
}

.slick-slide > div > div {
  display: block !important;
}

.sliderBg {
  transition: background-color 0.6s;
  position: relative;
}

.sliderLink {
  text-decoration: none;
}

.sliderBgInner {
  display: table;
  height: 101%;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  /* text-align: center; */
}
.sliderBgInner .centered {
  display: table-cell;
  color: #fff;
  vertical-align: middle;
  padding: 0rem 2rem;
}

.sliderBgInner .centered img {
  max-height: 320px;
  /* max-height: 40%; */
  width: auto;
  max-width: 100%;
  margin: 0 auto;
}

.sliderText {
  padding: 1rem 0;
}

/* .sliderText h1 {
  font-size: 8rem;
  line-height: 1em;
} */

.sliderCTA {
  padding: 1rem !important;
  min-width: 160px !important;
  font-style: normal;
  background-color: transparent !important;
  border-radius: 0 !important;
  border: solid !important;
  color: #ffffff !important;
  box-shadow: none !important;
}
.swiper-button-prev {
  left: 0;
  padding: 0 2rem;
}
.swiper-button-next {
  right: 0;
  padding: 0 2rem;
}

.swiper-button-prev:focus,
.swiper-button-next:focus {
  outline: none;
}
@media (max-width: 1024px) {
  .sliderText h1 {
    font-size: 4.6rem;
  }
  .swiper-button-prev {
    left: 0;
    padding: 0 1rem;
  }
  .swiper-button-next {
    right: 0;
    padding: 0 1rem;
  }
  .sliderBgInner .centered img {
    max-height: 260px;
  }
  /* .sliderBgInner .centered {
    font-size: 0.6em;
  } */
}

.sliderText {
  padding: 0;
  position: absolute;
  bottom: 6rem;
  right: 0;
  text-align: right;
  background-color: #0a0a0a;
}

.sliderText:before {
  content: "";
  position: absolute;
  height: 100%;
  background-color: #0a0a0a;
  width: 100px;
  top: 0;
  display: block;
  left: -48px;
  transform: skew(-30deg);
}

.sliderText h2 {
  font-size: 4rem;
  text-shadow: 1rem 0rem 0rem #0a0a0a;
}
.sliderText h1 {
  font-size: 8.2rem;
  text-shadow: 1rem 0rem 0rem #0a0a0a;
  margin: 0;
  padding: 1.4rem 4rem 0rem 0rem;
  z-index: 2;
  position: relative;
}

@media (max-height: 640px) {
  /* .swiper-button-prev {
    left: 0;
    padding: 0 1rem;
  }
  .swiper-button-next {
    right: 0;
    padding: 0 1rem;
  } */
  /* .sliderBgInner .centered img {
    max-height: 260px;
  } */
}

@media (max-width: 1024px) {
  .sliderText h1 {
    font-size: 6.8rem;
    text-shadow: 0.7rem 0rem 0rem #0a0a0a;
  }
  .sliderText h2 {
    font-size: 4rem;
  }
}

@media (max-width: 640px) {
  .sliderText {
    bottom: 0;
  }

  .sliderText {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .sliderText:before {
    display: none;
  }

  .sliderText h1 {
    font-size: 3.6rem;

    padding: 2rem;
  }
}
