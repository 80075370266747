.tabcontent {
  margin: 0 auto;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.tabcontentInner {
  display: block;
  width: auto;
  background: rgba(0, 0, 0, 0.8);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  /* background-color: rgba(0, 0, 0, 0.8); */
  color: #ffffff;
  padding: 1rem 2rem;
  padding-left: 20%;
  width: 100%;
  max-width: 100%;
}
.tabpanel > div {
  padding: 0 !important;
}
.MuiTabs-scrollButtonsDesktop {
  display: inline-flex !important;
}

.MuiTabs-flexContainer {
  justify-content: center;
}

@media (max-width: 640px) {
  .tabcontentInner {
    background: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    margin: 0 auto;
    padding-left: 2rem;
  }
  .MuiTabs-flexContainer {
    justify-content: initial;
  }
}
