.imageAndText img {
  width: 100%;
  display: block;
}

.imageAndText.reverse {
  background-position: right;
}

.imageAndText {
  position: relative;
  width: 100%;
}

.imageAndTextElem {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
.imageAndTextElem.reverse {
  flex-direction: row;
}

.imageAndTextElem.reverse .text {
  text-align: right;
}

.imageAndTextElem .image {
  width: 60% !important;
}

img.imageAndTextRight,
img.imageAndTextLeft {
  height: 100%;
  width: auto;
  position: absolute;
  top: 0;
}

img.imageAndTextRight {
  right: 39.9%;
}

img.imageAndTextLeft {
  left: 39.9%;
}

.imageAndTextElem .textContainer {
  color: #fff;
  left: 44.5%;
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: -400px;
  padding: 2rem;
}

.imageAndTextElem.reverse .textContainer {
  left: unset;
  right: 44.5%;
  margin-right: -400px;
}

.imageAndText h1 {
  font-size: 6.8rem;
  margin: 0;
  padding: 0;
  text-shadow: 0.7rem 0.7rem rgb(26, 26, 26);
  padding-right: 1.3rem;
}

.imageAndText p {
  margin: 0.5rem;
}

@media (max-width: 1024px) {
  .imageAndText {
    background-position: bottom;
  }
  .imageAndText.reverse {
    background-position: right bottom;
  }

  .imageAndTextElem .textContainer {
    margin: 0 !important;
    position: relative;
    width: 100% !important;
    left: 0 !important;
    padding: 4rem 2rem;
  }

  .imageAndText h1 {
    font-size: 4.2rem;
    margin: 0;
    padding: 0;
  }

  .imageAndTextElem {
    display: block;
  }

  .textInner {
    width: 100%;
  }

  .imageAndTextElem.reverse .textContainer {
    margin: 0 !important;
    position: relative;
    width: 100% !important;
    left: unset !important;
    right: 0 !important;
  }
  .imageAndTextElem .image {
    position: relative;
    width: 100% !important;
  }
  img.imageAndTextRight {
    right: 0;
    display: none;
  }

  img.imageAndTextLeft {
    left: 0;
    display: none;
  }
}

@media (max-width: 640px) {
  .imageAndText h1 {
    font-size: 2.6rem;
  }
  .imageAndText p {
    font-size: 1rem !important;
  }
}
