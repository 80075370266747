.formContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.form {
  max-width: 640px;
  padding: 2rem 2rem 4rem 2rem;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  /* background-color: #fff; */
}

.formContainer .description {
  max-width: 640px;
  width: 100%;
  padding: 2rem 2rem 4rem 2rem;

  box-sizing: border-box;
  /* background-color: #fff; */
}

.form h2 {
  padding: 0 1rem;
}

.form p {
  padding: 1rem;
}

.form > .formItem {
  box-sizing: border-box;
  padding: 0 1rem;
}

.form > .formItem .textfield {
  width: 100%;
}

.form > .formItem .textfield textarea {
  min-height: 200px;
}

.form .ctaContainer {
  width: 100%;
  padding: 1rem;
  text-align: right;
}

.form .ctaContainer button {
  min-width: 160px;
  padding: 1rem;
}

.form .checkbox {
  padding: 0.5rem 1rem;
}

.form .label {
  padding: 1rem 0 1rem 0;
  display: block;
  opacity: 0.5;
}

@media (max-width: 640px) {
  .form > .formItem {
    width: 100%;
  }

  .form .ctaContainer button {
    width: 100%;
  }
}
